import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    categoryId: route.query.categoryId,
    categoryNames: proxy.$_.keyBy(proxy.$ConstCode.CATEGORY2_VALUE, 'id'),
    conflict: [],
    member: {},
    chatroomId: '',
    viewResOptions: computed(() =>
      proxy.$Util.isEmpty(state.member)
        ? false
        : ['resKakao', 'resPhone', 'resEmail'].some(o => state.member[o])
    ),
    runningConflict: computed(() => {
      if (proxy.$Util.isEmpty(state.conflict)) return {}
      return proxy.$_.find(state.conflict, { status: '0' })
    })
  })
  const fnDetail = async () => {
    const params = {}
    params.partyNo = route.query.partyNo
    params.memberSeq = route.query.memberSeq
    const res = await proxy.$MyPageSvc.postPartyMemberDetail(params)
    if (res.resultCode === '0000') {
      state.member = res.member
      state.conflict = res.conflict
      state.chatroomId = res.chatroomId
    } else {
      alert(res.resultMsg)
    }
  }
  const fnWizzTalk = async () => {
    await router.push({
      path: '/wizz-talk',
      query: {
        partyNo: route.query.partyNo,
        chatroomId: state.chatroomId
      }
    })
  }

  const fnRefund = async () => {
    if (
      !confirm(
        '취소 환불을 승인하시겠습니까? 승인 시 파티원의 신청일부터 잔여기간까지 비용이 환불됩니다.'
      )
    )
      return

    const params = {}
    params.seq = state.runningConflict.seq
    const res = await proxy.$PartySvc.postPartyCancelRefundAccept(params)
    if (res.resultCode === '0000') {
      alert('취소 환불되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }
  const fnRefundReject = async (isEdit = 1, item = {}) => {
    console.log(isEdit, item)
    const payload = {
      component: proxy.$modalAlertNames.PARTY_REFUND_REJECT,
      data: {
        seq:
          proxy.$ConstCode.BOOLEAN_VALUE.TRUE === isEdit
            ? item.seq
            : state.runningConflict.seq,
        reply: proxy.$ConstCode.BOOLEAN_VALUE.TRUE === isEdit ? item.reply : '',
        isEdit: isEdit
      },
      callback: async () => {
        await fnDetail()
      }
    }
    await fnModalAlert(payload)
  }
  const fnMsg = item => {
    if (item.paymentType === '위즈캐시') {
      if (Number(item.point) > 0) {
        return `파티장 (자동) 승인에 따라, 총 ${proxy.$Util.formatNumber2(
          item.partyAmt,
          { unit: '캐시' }
        )} 및 ${proxy.$Util.formatNumber2(item.point, {
          unit: '포인트'
        })}가 환급되었습니다.`
      } else {
        return `파티장 (자동) 승인에 따라, 총 ${proxy.$Util.formatNumber2(
          item.partyAmt,
          { unit: '캐시' }
        )}가 환급되었습니다.`
      }
    } else {
      // 카드결제
      if (Number(item.point) > 0) {
        return `파티장 (자동) 승인에 따라, 카드 결제내역이 ${proxy.$Util.formatNumber2(
          item.partyAmt,
          { unit: '원' }
        )} (부분) 취소되었으며, ${proxy.$Util.formatNumber2(item.point, {
          unit: '포인트'
        })}가 환급되었습니다.`
      } else {
        return `파티장 (자동) 승인에 따라, 카드 결제내역이 ${proxy.$Util.formatNumber2(
          item.partyAmt,
          { unit: '원' }
        )} (부분) 취소되었습니다.`
      }
    }
  }
  const fnReplyCopy = () => {
    var t = document.createElement('textarea')
    document.body.appendChild(t)
    t.value = state.member.reply
    t.select()
    document.execCommand('copy')
    document.body.removeChild(t)
    alert('텍스트가 복사되었습니다.')
  }
  const init = async () => {
    await fnDetail()
    const lumps = document.querySelectorAll('.lump')
    proxy.$_.last(lumps).classList.add('bo_zero')
  }
  init()
  return {
    ...toRefs(state),
    fnWizzTalk,
    fnRefund,
    fnRefundReject,
    fnMsg,
    fnReplyCopy
  }
}
