<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner" v-if="!$Util.isEmpty(member)">
        <div class="page_con">
          <!--          <div class="con_ttl">-->
          <!--            <span>파티원 등록 정보</span>-->
          <!--          </div>-->
          <div class="detail_top">
            <div class="con_ttl" style="padding: 0 0 0.8rem;">
              <span>파티원 등록 정보</span>
            </div>
            <div class="party_tag big center">
              <!--'상세페이지에서: big'-->
              <div class="left">
                <div
                  class="pt_tag logo"
                  style="--pt-txt-color: #fff;"
                  :class="{ sl: member.acntTransferYn === 'N' }"
                  :style="{
                    '--pt-bg-color': $ConstCode.getCategoryById(categoryId)
                      .color
                  }"
                >
                  {{ categoryNames[categoryId].text }}
                </div>
                <div
                  class="pt_tag normal nb blue"
                  v-if="member.acntTransferYn === 'Y'"
                >
                  양도
                </div>
              </div>
            </div>
            <div class="party_ttl">{{ member.title }}</div>
            <div
              class="progress"
              :class="{
                pause:
                  member.statusNm === '일시정지' ||
                  member.statusNm === '취소/환불' ||
                  member.statusNm === '종료'
              }"
            >
              <!--'일시정지: pause'-->
              <span class="percent">{{
                $Util.formatNumber2(member.progressRate, { unit: '%' })
              }}</span>
              <div
                class="bar"
                :style="{
                  '--progress-percent': !$Util.isEmpty(member.progressRate)
                    ? `${member.progressRate}%`
                    : '0%'
                }"
              ></div>
            </div>
          </div>
          <div class="lump" style="border: 0;">
            <div class="table_type01">
              <dl>
                <dt>결제금액</dt>
                <dd v-if="member.acntTransferYn === 'N'">
                  {{ $Util.formatNumber2(member.paymentAmt, { unit: '원' })
                  }}<br />
                  <span class="daily">{{
                    `(${$Util.formatNumber2(member.dailyPrice, {
                      unit: '원'
                    })})`
                  }}</span>
                </dd>
                <dd v-else>
                  {{ $Util.formatNumber2(member.paymentAmt, { unit: '원' }) }}
                </dd>
              </dl>
              <dl>
                <dt>누적 캐시</dt>
                <dd>
                  <span
                    ><span class="color">{{
                      $Util.formatNumber2(member.cumulativeAmt, { unit: '' })
                    }}</span>
                    C</span
                  >
                </dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>정산 기간</dt>
                <dd class="space_between">
                  <span>{{
                    member.unlimitedCheckYn === 'N'
                      ? `${$dayjs(member.regDt)
                          .add(2, 'd')
                          .format('YYYY.MM.DD')} ~ ${$dayjs(member.useDt)
                          .add(1, 'd')
                          .format('YYYY.MM.DD')}`
                      : '표시없음'
                  }}</span>
                  <span
                    v-if="
                      $Util.isNumeric(member.statusNm) &&
                        member.unlimitedCheckYn === 'N'
                    "
                    ><span class="color">{{ member.statusNm }}</span
                    >일 남음</span
                  >
                  <span v-else>
                    <span
                      class="color"
                      :class="[
                        {
                          grey:
                            member.statusNm === '취소/환불' ||
                            member.statusNm === '종료'
                        },
                        {
                          error: member.statusNm === '일시정지'
                        }
                      ]"
                      >{{
                        member.statusNm === '일시정지' ? '일시정지' : '종료'
                      }}</span
                    ></span
                  >
                </dd>
              </dl>
              <dl>
                <dt>이용 기간</dt>
                <dd class="space_between">
                  <span>{{
                    member.unlimitedCheckYn === 'N'
                      ? `${$dayjs(member.regDt).format(
                          'YYYY.MM.DD'
                        )} ~ ${$dayjs(member.useDt)
                          .subtract(1, 'd')
                          .format('YYYY.MM.DD')}`
                      : '표시없음'
                  }}</span>
                  <span
                    v-if="
                      $Util.isNumeric(member.statusNm) &&
                        member.unlimitedCheckYn === 'N'
                    "
                    ><span class="color">{{ member.statusNm }}</span
                    >일 남음</span
                  >
                  <span v-else>
                    <span
                      class="color"
                      :class="[
                        {
                          grey:
                            member.statusNm === '취소/환불' ||
                            member.statusNm === '종료'
                        },
                        {
                          error: member.statusNm === '일시정지'
                        }
                      ]"
                      >{{
                        member.statusNm === '일시정지' ? '일시정지' : '종료'
                      }}</span
                    ></span
                  >
                </dd>
              </dl>
              <dl>
                <dt>결제자 정보</dt>
                <dd class="buyer_info">
                  <span class="name">{{ member.nick }}</span>
                  <!--                  <span>{{ member.nick }}</span>-->
                  <div
                    class="sv_tag"
                    :class="[
                      {
                        kakao:
                          member.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.KAKAO.enName
                      },
                      {
                        naver:
                          member.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.enName
                      },
                      {
                        google:
                          member.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.enName
                      },
                      {
                        apple:
                          member.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.APPLE.enName
                      }
                    ]"
                    v-if="member.loginType !== 'wizzney'"
                  >
                    {{ member.loginType }}
                  </div>
                </dd>
              </dl>
              <!--              <dl>-->
              <!--                <dt>총 결제액</dt>-->
              <!--                <dd class="space_between">-->
              <!--                  <div>-->
              <!--                    {{-->
              <!--                      `${$Util.formatNumber2(-->
              <!--                        Number(member.partyAmt) + Number(member.point),-->
              <!--                        { unit: '' }-->
              <!--                      )}${-->
              <!--                        !$Util.isEmpty(member.point) && Number(member.point) > 0-->
              <!--                          ? ' - '-->
              <!--                          : ''-->
              <!--                      }`-->
              <!--                    }}-->
              <!--                    <span-->
              <!--                      class="color"-->
              <!--                      v-if="-->
              <!--                        !$Util.isEmpty(member.point) && Number(member.point) > 0-->
              <!--                      "-->
              <!--                      >{{-->
              <!--                        $Util.formatNumber2(member.point, { unit: 'P' })-->
              <!--                      }}</span-->
              <!--                    >-->
              <!--                  </div>-->
              <!--                  <div class="total">-->
              <!--                    <span class="sec_color">{{-->
              <!--                      $Util.formatNumber2(member.partyAmt, { unit: '' })-->
              <!--                    }}</span>-->
              <!--                    원-->
              <!--                  </div>-->
              <!--                </dd>-->
              <!--              </dl>-->
              <dl>
                <dt>결제 방법</dt>
                <dd>{{ member.paymentType }}</dd>
              </dl>
              <dl>
                <dt>결제일</dt>
                <dd>{{ $dayjs(member.paymentDt).format('YYYY.MM.DD') }}</dd>
              </dl>
              <dl>
                <dt>파티 순서</dt>
                <dd>{{ member.memberNo }}/{{ member.recruitNum }}</dd>
              </dl>
            </div>
          </div>
          <div v-if="viewResOptions" class="lump bo_zero">
            <div class="form">
              <div class="form_ttl" style="color: #999; font-weight: 500;">
                파티원 요청정보
              </div>
              <ul class="form_ul">
                <li v-if="member.resKakao" class="form_li">
                  <div class="form_ttl essen">카카오톡 ID</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="카카오톡 ID를 입력해주세요"
                        v-model="member.resKakao"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="member.resPhone" class="form_li">
                  <div class="form_ttl essen">휴대폰번호</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="휴대폰번호를 입력해 주세요"
                        v-model="member.resPhone"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="member.resEmail" class="form_li">
                  <div class="form_ttl essen">이메일</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="이메일을 입력해주세요"
                        v-model="member.resEmail"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="lump bo_zero" v-if="member.optionYn === 'Y'">
            <!--210820 bo_zero 추가-->
            <div class="table_type02">
              <dl>
                <!--210810수정-->
                <dt>
                  정보 입력<span class="table_date"
                    >{{ $dayjs(member.optionDt).format('YYYY.MM.DD') }}
                    <!-- <span class="color_error"> N</span>-->
                  </span>
                </dt>
                <!--210810수정-->
                <dd class="editor">
                  <div>
                    <!--붙여쓰기-->{{ member.reqOptionMsg
                    }}<!--붙여쓰기-->
                  </div>
                  <div style="white-space: inherit; margin-top: 1rem;">
                    <label class="input" @click="fnReplyCopy">
                      <!--210810 파티장은 정보입력란에 입력할 수 없고 파티원이 작성해준 내용만을 확인하고 선택 시 복사가 돼야합니다.-->

                      <!--정보를 입력하지 않았을 때 -->
                      <!--<textarea class="info_none" disabled>파티원이 정보를 입력하지 않았습니다. 위즈톡으로 정보 요청 입력이 가능하도록 확인해주세요.</textarea>-->

                      <!--정보를 입력했을 때 -->
                      <textarea
                        class="info_wrote"
                        disabled=""
                        v-model="member.reply"
                      ></textarea>
                      <!--//210810 추가 끝-->
                    </label>
                  </div>
                </dd>
                <!--//210810수정끝-->
              </dl>
            </div>
          </div>
          <div class="status" v-if="!$Util.isEmpty(conflict)">
            <div class="inner" v-for="(item, index) in conflict" :key="index">
              <div>
                <div>
                  <dl class="status_sec">
                    <dt class="status_ttl">
                      취소/환불 신청
                      <span class="status_date">{{
                        $dayjs(item.regDt).format('YYYY.MM.DD')
                      }}</span>
                    </dt>
                    <dd class="status_main">
                      <div class="status_sec_ttl">
                        <div class="reason">
                          <span class="sec_color">{{
                            Number(item.option) === 0
                              ? '단순 변심'
                              : '파티장 과실'
                          }}</span>
                        </div>
                      </div>
                      <div class="editor">
                        <div v-html="item.msg"></div>
                      </div>
                    </dd>
                    <dd class="status_sub">
                      <div>
                        · 파티장은 취소/환불 신청일로부터 3일 이내에 취소/환불
                        또 는 반려 처리해야 합니다. 3일 이후는 자동 취소/환불
                        승인됩니다.
                      </div>
                      <div>
                        · 취소/환불 승인은 신청일 기준으로 처리됩니다.
                      </div>
                      <div>
                        · 취소 환불 후 파티원은 잔여일 수 기준 자동으로 추가
                        모집 진행됩니다.
                      </div>
                    </dd>
                  </dl>
                  <dl class="status_sec" v-if="item.status === '1'">
                    <dt class="status_ttl">
                      취소/환불 안내
                      <span
                        class="status_date"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                        >{{
                          $dayjs(item.replyRegDt).format('YYYY.MM.DD')
                        }}</span
                      >
                    </dt>
                    <dd class="status_main">
                      <div class="status_sec_ttl">
                        <div class="reason">
                          <span class="color">승인 완료</span>
                        </div>
                      </div>
                      <div class="editor">
                        <div>
                          파티장 (자동) 승인에 따라 결제내역이 (부분)
                          취소되었습니다.
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl class="status_sec" v-if="item.status === '2'">
                    <dt class="status_ttl">
                      취소/환불 안내
                      <span
                        class="status_date"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                        >{{
                          $dayjs(item.replyRegDt).format('YYYY.MM.DD')
                        }}</span
                      >
                    </dt>
                    <dd class="status_main">
                      <div class="status_sec_ttl">
                        <div class="reason">
                          <span class="error">신청 반려</span>
                        </div>
                        <button
                          class="btn_revise"
                          @click="
                            fnRefundReject($ConstCode.BOOLEAN_VALUE.TRUE, item)
                          "
                        ></button>
                      </div>
                      <div class="editor">
                        <div v-html="item.reply"></div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div class="lump" style="border: 0;" v-if="item.status === '3'">
                <div class="table_type02">
                  <dl>
                    <dt>
                      분쟁 취소 안내
                      <span
                        class="table_date"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                        >{{ $dayjs(item.regDt).format('YYYY.MM.DD') }}</span
                      >
                    </dt>
                    <dd class="editor">
                      <div>
                        <!--붙여쓰기-->취소 환불 신청이 취소되었습니다.<!--붙여쓰기-->
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div class="con_btn">
            <div class="btn_wrap">
              <button
                class="btn bo"
                @click="fnWizzTalk"
                v-if="member.wizztalkYn === 'Y'"
              >
                위즈톡<span class="wiz_icon"></span>
              </button>
              <button
                class="btn bo"
                @click="fnRefund"
                v-if="!$Util.isEmpty(runningConflict)"
              >
                취소/환불
              </button>
            </div>
            <div class="btn_wrap">
              <button
                class="btn bo"
                @click="fnRefundReject"
                v-if="!$Util.isEmpty(runningConflict)"
              >
                신청 반려
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-created-party-member-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
